
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

label{
  display: none;
}

.row {
 display:flex;
 flex-flow: row;
justify-content: center;
padding: 2%;
}


.footer-container{
  background-color: #D3EEE1;
height: 10%;
width: auto;
padding-top: 3rem;
margin-top: 3rem;
}



.menu-box {
display: flex;
flex-flow: row;
justify-content: space-around;

}

a {
  text-decoration: none;
  font-family: 'Raleway', sans-serif;
  color: black;
  -webkit-transition: color 1s;
  transition: color .3s;
}

h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 500 bold;
  

}

h3 {
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

p {
  font-family: 'Raleway', sans-serif;
  padding: 0px;
  margin: 0px;
}

.black-box {
display: flex;
flex-flow: row wrap;
justify-content: center;
}

.proj-img {
  height: 43%;
  width: 43%;

}


.main-box {
  display:flex;
  flex-flow: column;
  justify-content: space-between;
  height: 90%;
  margin-top: 5%;

}



a:hover {
  color: #D3EEE1;

}


.container-fluid {
  display: flex;
  height: 99%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 7rem;
}

.home-msg {
  display: flex;
  flex-flow: column;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
} 

.home-msg-text {
  margin-top: 3rem;
  margin-bottom: 2.5rem;
}

.sect-title {
  color: rgba(211, 16, 30, 0.7)
}

.circle-one {
  display: flex;
  height: 12rem;
  width: 12rem;
  background-color: rgba(211,225,223, .3);
  border-radius: 50%;
  position: relative;
  left: 1%;
  z-index: -3;
}

.circle-two {
  display: flex;
  height: 12rem;
  width: 12rem;
  background-color: rgba(211,238,225, .3);
  border-radius: 50%;
  z-index: -2;

}

.circle-three {
  display: flex;
  height: 12rem;
  width: 12rem;
  background-color: rgba(211, 16, 30, 0.3);
  border-radius: 50%;
  position: relative;
  right: 1%;
  z-index: -1;

}

.name-overlay{
  display: flex;
  align-self: center;
  position: absolute;
  z-index: 1;
  display: block;
}

.title-text {
  font-size: 500%;
  padding: 0px;
  margin: 0px;
  z-index: 2;
}

.under-text {
  font-size: 180%;
  padding: 0px;
  margin: 0px;
  text-align: center;
  z-index: 3;
}

.tech-stack-box {
display: flex;
flex-flow: row wrap;
justify-content: space-evenly;
font-size: 4rem;
}

i {
  margin: .4rem;
}

.education-box{
text-align: center;
}

.size-80{
  height:80px;
  width:80px
}

.size-64{
  height:64px;
  width:64px
}

.resume-link{
  text-align: center;
  margin: 0px;
  font-style: italic;
  color: rgb(171, 183, 181)
}
.resume-box{
display: flex;
flex-flow: column;
justify-content:space-evenly;
}
.tech-experience-box{
  width: 100%; 
display: flex;
flex-flow: row wrap;
justify-content: center;
}

.single-resume-item{
width: 45%;
margin: .5rem;
display: flex;
flex-flow: column;
}

.resume-head {
margin: 1rem;
}

.contact-box{
text-align: center;
margin-top: 3%;
}
  
.form-box{
width: 100%;
text-align: center;
padding: 5rem;
}

.form-items{
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

input{
  text-align:center;
  margin: 1rem;
  border: 3px solid rgb(171, 183, 181); 
  font-size: 1.5rem;
}

.message{
  padding: 3rem;
}
  
button{
  background-color: rgba(211, 16, 30, 0.3);
  border: none;
  width: 100;
  height: 10%;
  font-size: 150%;
  margin-bottom: 10%;
}

.other-contact-box{
display: flex;
flex-flow: column;
}

.live-buttons{
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.live-buttons a{
  background-color:  rgba(211, 16, 30, 0.3);
  border: .1rem solid black;
  display: flex;
padding: .5rem;
margin: .3rem;
align-items: center;
justify-content: center;
width: 4rem;
font-size: 1rem;
}

.main-page {
  text-align: center;
  padding: 5rem;
}
@media only screen and (max-width: 1024px) {

  .live-buttons{
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
    margin-top: 0%;

  }

  .resume-head {
    margin: 1.5rem;
    }

  .tech-experience-box{
    width: 100%; 
  display: flex;
  flex-flow: column;
  justify-content: center;

  }
  
  .single-resume-item{
    width: 100%;
    margin: .5rem;
    }

    
}


@media only screen and (max-width: 700px) {


  .circle-one {
    display: flex;
    height: 10rem;
    width: 10rem;
    background-color: rgba(211,225,223, .3);
    border-radius: 50%;
    position: relative;
    left: 1%;
    z-index: -3;
  }
  
  .circle-two {
    display: flex;
    height: 10rem;
    width: 10rem;
    background-color: rgba(211,238,225, .3);
    border-radius: 50%;
    z-index: -2;
  
  }
  
  .circle-three {
    display: flex;
    height: 10rem;
    width: 10rem;
    background-color: rgba(211, 16, 30, 0.3);
    border-radius: 50%;
    position: relative;
    right: 1%;
    z-index: -1;
  
  }
  
  .name-overlay{
    display: flex;
    align-self: center;
    position: absolute;
    z-index: 1;
    display: block;
  }
  
  .title-text {
    font-size: 400%;
    padding: 0px;
    margin: 0px;
    z-index: 2;
  }
  
  .under-text {
    font-size: 140%;
    padding: 0px;
    margin: 0px;
    text-align: center;
    z-index: 3;
  }



  .proj-img {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 1500px) {
  .proj-img {
    height: 90%;
    width: 90%;
  }
}

@media only screen and (max-width: 1000px) {
  .proj-img {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {

  .circle-one {
    display: flex;
    height: 8rem;
    width: 8rem;
    background-color: rgba(211,225,223, .3);
    border-radius: 50%;
    position: relative;
    left: 1%;
    z-index: -3;
  }
  
  .circle-two {
    display: flex;
    height: 8rem;
    width: 8rem;
    background-color: rgba(211,238,225, .3);
    border-radius: 50%;
    z-index: -2;
  
  }
  
  .circle-three {
    display: flex;
    height: 8rem;
    width: 8rem;
    background-color: rgba(211, 16, 30, 0.3);
    border-radius: 50%;
    position: relative;
    right: 1%;
    z-index: -1;
  
  }
  
  .name-overlay{
    display: flex;
    align-self: center;
    position: absolute;
    z-index: 1;
    display: block;
  }
  
  .title-text {
    font-size: 300%;
    padding: 0px;
    margin: 0px;
    z-index: 2;
  }
  
  .under-text {
    font-size: 150%;
    padding: 0px;
    margin: 0px;
    text-align: center;
    z-index: 3;
  }


  h1, h2, h3, a, p {
    font-size: 130%;
  }

  .black-box {
    padding-bottom: 3rem;
  }


}

@media only screen and (max-width: 410px) {

  .circle-one {
    display: flex;
    height: 7rem;
    width: 7rem;
    background-color: rgba(211,225,223, .3);
    border-radius: 50%;
    position: relative;
    left: 1%;
    z-index: -3;
  }
  
  .circle-two {
    display: flex;
    height: 7rem;
    width: 7rem;
    background-color: rgba(211,238,225, .3);
    border-radius: 50%;
    z-index: -2;
  
  }
  
  .circle-three {
    display: flex;
    height: 7rem;
    width: 7rem;
    background-color: rgba(211, 16, 30, 0.3);
    border-radius: 50%;
    position: relative;
    right: 1%;
    z-index: -1;
  
  }
  
  .name-overlay{
    display: flex;
    align-self: center;
    position: absolute;
    z-index: 1;
    display: block;
  }
  
  .title-text {
    font-size: 200%;
    padding: 0px;
    margin: 0px;
    z-index: 2;
  }
  
  .under-text {
    font-size: 130%;
    padding: 0px;
    margin: 0px;
    text-align: center;
    z-index: 3;
  }



    h1, h2, h3, a, p {
      font-size: 120%;
    }


    .black-box {
      padding-bottom: 4rem;
    }


}